body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ************ Scrollbar ********** */

/* Width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  display: none;
}

/* Track */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* handle */
::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.timeline-hyperlink {
  padding-top: 13px;
  padding-bottom: 13px;
}

@media (max-width: 768px) {
  .timeline-hyperlink {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .selectedTimeline .timeline-hyperlink:not(.selected) {
    display: none !important;
  }

  .timeline-hyperlink.selected {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .timeline-container {
    position: sticky !important;
    z-index: 1;
    background-color: #fff;
  }

  /* .scrolling .timeline-container {
    position:sticky;
    width:100%;
    background-color:#fff;
    top:0;
    left:0;
    overflow:hidden !important;
    z-index:1;
    background:#ffffff;
  } */

  /* .scrolling .timeline-container .timeline-list {
    height: 200px;
    overflow-y: hidden !important;
  }

  .scrolling #line-chart-container {
    overflow-y: hidden !important;
  } */
}
