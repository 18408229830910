/* Footer Styling */
.footer-container {
  background-color: #3d3d3d;
  padding: 10px 0px 0px 0px;
  min-height: 15vh;
  position: relative;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
.footer-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.footer-content-2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 20px;
}
.footer-content-2-text {
  color: white;
  margin-left: 50px;
}
.footer-img {
  width: 50%;
}

.header-img {
  width: 100%;
}
@media (max-width: 768px) {
  .footer-container {
    width: 100%;
    min-height: 15vh;
    background-color: #3d3d3d;
  }
  .footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
  }
  .footer-content-2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
  }
  .footer-content-2-text {
    color: white;
    font-size: 12px;
    margin-left: 0px;
  }
  .footer-img,
  .header-img {
    width: 90px;
  }
}
