.main-container {
  padding: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 3%;
}
.header-page-title {
  /* font-family: corner-deli-t-regular; */
  font-size: 90px;
  color: white;
  margin-bottom: 5%;
}
.form-container {
  text-align: center;
  backdrop-filter: blur(10px);
  position: relative;
  background: linear-gradient(
    to right,
    rgba(236, 236, 236, 0.3) 37.5%,
    rgba(236, 236, 236, 0.2) 52.5%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 810px;
  width: 600px;
  height: 470px;
  border-radius: 20px;
}
.header-icon {
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
}
.form-title {
  font-size: 50px;
  color: black;
  margin-bottom: 25px;
  font-weight: 600;
}
.grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
}
.feedback-page-title {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
}

.feedback-page-message {
  color: #3d3d3d;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  margin-top: 15px;
  width: 70%;
}

.feedback-page-back-to-login {
  margin-top: 5%;
}
.feedback-hint-message {
  color: #3d3d3d;
  text-align: center;
  font-size: 19px;
  margin-top: 10px;
}
.login-form-button-text {
  text-decoration: none;
  color: white;
  font-weight: 500;
}
.error-message-text {
  color: #d32f2f;
}
/* Mobile View */
@media (max-width: 600px) {
  .header-page-title {
    font-size: 30px;
    margin-bottom: 40px;
    margin-top: 10px;
  }

  .form-title {
    font-size: 36px;
  }
  .form-container {
    text-align: center;
    backdrop-filter: blur(10px);
    position: relative;
    background: linear-gradient(
      to right,
      rgba(236, 236, 236, 0.3) 37.5%,
      rgba(236, 236, 236, 0.2) 52.5%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 420px;
    border-radius: 20px;
  }
  .form-title {
    font-size: 36px;
  }
  .feedback-page-title {
    font-size: 36px;
    padding: 5px;
  }
  .feedback-page-message {
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .feedback-hint-message {
    padding: 0px 20px;
    font-size: 11px;
  }
}
